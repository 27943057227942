import React from "react";
import Img from "gatsby-image";
import Section from "../../components/Section/Section";
import "./StackedImages.scss";

const StackedImages = ({ images }) => {
  return (
    <Section className="StackedImages">
      <div className="container">
        {images.map(({ image }, i) => {
          const img = (image.useImage) ? image.useImage().imgData : image?.localFile?.childImageSharp.fluid;
          return (
            <div className="StackedImage" key={i}>
              <Img fluid={img} alt={image?.altText} />
            </div>
          );
        })}
      </div>
    </Section>
  );
};

export default StackedImages;
